import React from "react";

import "./styles/Comprobante.css";
import imagenpdf from "../images/icon_pdf_2.png";
import imagenxml from "../images/icon_xml_2.png";
import imagenok from "../images/icon_ok.png";
import api from "../api";

class ComprobantesList extends React.Component {
  render() {
    let entidades = this.props.data.map(function(entidad, index) {
      if (entidad.Codigo == "0") {
        return (
          <tr>
            <td scope="row">{index + 1}</td>
            <td>{entidad.TipoComprobante} </td>
            <td>{entidad.EmisorRUC}</td>
            <td>{entidad.EmisorRazonSocial}</td>
            <td>
              {entidad.Serie}-{entidad.Numero}
            </td>
            <td>{entidad.FechaEmision}</td>
            <td>{entidad.Moneda}</td>
            <td>{entidad.ImporteTotal}</td>
            <td>
              <img src={imagenok}></img>
            </td>
            <td>
              <a href={api.descargas.downloadxml(entidad.ID)}>
                <img src={imagenxml}></img>
              </a>
              /
              <a href={api.descargas.downloadpdf(entidad.ID)}>
                <img src={imagenpdf}></img>
              </a>
            </td>
          </tr>
        );
      } else {
        return (
          <tr>
            <td scope="row">{index + 1}</td>
            <td>{entidad.TipoComprobante} </td>
            <td>{entidad.EmisorRUC}</td>
            <td>{entidad.EmisorRazonSocial}</td>
            <td>
              {entidad.Serie}-{entidad.Numero}
            </td>
            <td>{entidad.FechaEmision}</td>
            <td>{entidad.Moneda}</td>
            <td>{entidad.ImporteTotal}</td>
            <td></td>
            <td>
              <a href={api.descargas.downloadxml(entidad.ID)}>
                <img src={imagenxml}></img>
              </a>
              /
              <a href={api.descargas.downloadpdf(entidad.ID)}>
                <img src={imagenpdf}></img>
              </a>
            </td>
          </tr>
        );
      }
    });

    return (
      <div class="table-responsive-sm">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Tipo</th>
              <th scope="col">Emisor</th>
              <th scope="col">Razón Social</th>
              <th scope="col">Serie-Número</th>
              <th scope="col">Fecha Emisión</th>
              <th scope="col">Moneda</th>
              <th scope="col">Total</th>
              <th scope="col">Estado</th>
              <th scope="col">
                <bold>↓</bold> XML/PDF
              </th>
            </tr>
          </thead>
          <tbody>{entidades}</tbody>
        </table>
      </div>
    );
  }
}

export default ComprobantesList;
