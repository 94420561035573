import React from "react";
import ReactPaginate from "react-paginate";

import "./styles/Comprobante.css";
import PageLoading from "../components/PageLoading";
import PageError from "../components/PageError";
import ComprobanteList from "./ComprobanteList";
import api from "../api";
import imagencsv from "../images/icon_csv.png";

class Comprobantes extends React.Component {
  horaformato = e => {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd + "";
    return today;
  };

  state = {
    loading: true,
    error: null,
    data: undefined,
    entidadFiltro: {
      pageCount: 0,
      offset: 0,
      totalRegistros: 0,
      perPage: 10
    },
    entidadConsulta: null
  };

  //Si el constructor está activo
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.location.state) {
      var objeto = this.props.location.state;
      this.setState({
        loading: false,
        data: objeto.data.data,
        entidadFiltro: Object.assign({}, this.state.entidadFiltro, {
          totalRegistros: objeto.data.TotalRegistros,
          pageCount: Math.ceil(
            objeto.data.TotalRegistros / this.state.entidadFiltro.perPage
          ) // + 1
        }),
        entidadConsulta: objeto.parametrosconsulta
      });
    } else if (this.state.entidadConsulta) {
      this.loadFromServer();
    } else {
      this.props.history.push("/");
    }
  }

  loadFromServer = async () => {
    const objetoEnvio = this.state.entidadConsulta;
    objetoEnvio.entidadFiltro = this.state.entidadFiltro;

    const xrpta = await api.comprobantes.search(objetoEnvio);
    var rpta = xrpta[0];
    this.setState({
      data: rpta.data,
      entidadFiltro: Object.assign({}, this.state.entidadFiltro, {
        totalRegistros: rpta.TotalRegistros,
        pageCount: Math.ceil(
          rpta.TotalRegistros / this.state.entidadFiltro.perPage
        ) //+ 1
      })
    });
  };

  handlePageClick = data => {
    let selected = data.selected;
    let _offset = Math.ceil(selected * this.state.entidadFiltro.perPage);

    this.setState(
      {
        entidadFiltro: Object.assign({}, this.state.entidadFiltro, {
          offset: _offset
        })
      },
      () => {
        this.loadFromServer();
      }
    );
  };

  exportarCSV = async e => {
    e.preventDefault();
    this.setState({ loading: true, error: null });
    const objetoEnvio = this.state.entidadConsulta;
    objetoEnvio.entidadFiltro = this.state.entidadFiltro;
    try {
      const rpta = await api.descargas.exportcsv(objetoEnvio);
      console.log(rpta.headers);
      this.handleResponse(rpta);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  handleResponse = response => {
    /* var blob = new Blob([new Uint8Array(response.Archivo)], {
      type: response.TipoArchivo
    }); */
    var blob = new Blob(
      ["\ufeff", response.Archivo]
      /* {
      type: "text/csv;charset=utf-8"
    } */
    );

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    console.log(url);
    link.href = url;
    link.download = response.NombreArchivo;
    link.click();
  };

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    let paginationElement;
    if (this.state.entidadFiltro.pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={<span className="gap">...</span>}
          pageCount={this.state.entidadFiltro.pageCount}
          onPageChange={this.handlePageClick}
          forcePage={this.state.currentPage}
          disabledClassName={"disabled"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      );
    }

    if (this.state.loading == true && !this.state.data) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError error={this.state.error} />;
    }

    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                Exportar:{" "}
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={this.exportarCSV}
                >
                  CSV
                </button>
              </div>
              {/* {paginationElement} */}
              <ComprobanteList data={this.state.data} />
              {paginationElement}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Comprobantes;
