import React from "react";

class BusquedaForm extends React.Component {
  render() {
    return (
      <div>
        <form onSubmit={this.props.onSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                Seleccione los criterios para la búsqueda de comprobantes
              </div>
            </div>
            <div className="row row-bordered">
              <div className="col-3">
                <input
                  onChange={this.props.onChange}
                  type="checkbox"
                  name="factura"
                  checked={this.props.formValues.factura}
                  /* value={this.props.formValues.factura} */
                />
                Factura
              </div>
              <div className="col-3">
                <input
                  onChange={this.props.onChange}
                  type="checkbox"
                  name="boleta"
                  checked={this.props.formValues.boleta}
                  /* value={this.props.formValues.boleta} */
                />
                Boleta
              </div>
              <div className="col-3">
                <input
                  onChange={this.props.onChange}
                  type="checkbox"
                  name="notacredito"
                  checked={this.props.formValues.notacredito}
                  /* value={this.props.formValues.notacredito} */
                />
                Nota Crédito
              </div>
              <div className="col-3">
                <input
                  onChange={this.props.onChange}
                  type="checkbox"
                  name="notadebito"
                  checked={this.props.formValues.notadebito}
                  /* value={this.props.formValues.notadebito} */
                />
                Nota Débito
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label>Emisor</label>
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="text"
                    name="ruc"
                    value={this.props.formValues.ruc}
                  />
                </div>
              </div>
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
              {/* <div className="col-3" style={{ padding: "0px" }}>
                <div className="short-div row-bordered">
                  <h6>Filtro x Estado</h6>
                </div>
                <div className="short-div">
                  <input
                    onChange={this.props.onChange}
                    type="checkbox"
                    name="validosunat"
                    checked={this.props.validosunat}
                  />
                  Válido SUNAT
                </div>
                <div className="short-div">
                  <input
                    onChange={this.props.onChange}
                    type="checkbox"
                    name="pendientesunat"
                    checked={this.props.pendientesunat}
                  />
                  Pendiente SUNAT
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label>Serie</label>
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="text"
                    name="serie"
                    value={this.props.formValues.serie}
                  />
                </div>
              </div>
              <div className="col-3"></div>
              <div className="col-6"></div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  Número Inicio
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="text"
                    name="numeroinicio"
                    value={this.props.formValues.numeroinicio}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  Número Fin
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="text"
                    name="numerofin"
                    value={this.props.formValues.numerofin}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group center">
                  <div className="">
                    <input
                      onChange={this.props.onChange}
                      type="checkbox"
                      name="validosunat"
                      checked={this.props.formValues.validosunat}
                    />
                    Válido SUNAT
                  </div>
                  <div className="">
                    <input
                      onChange={this.props.onChange}
                      type="checkbox"
                      name="pendientesunat"
                      checked={this.props.formValues.pendientesunat}
                    />
                    Pendiente SUNAT
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  Monto Inicio
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="number"
                    name="montoinicio"
                    value={this.props.formValues.montoinicio}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  Monto Fin
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="number"
                    name="montofin"
                    value={this.props.formValues.montofin}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  Fecha Inicio
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="date"
                    name="fechainicio"
                    value={this.props.formValues.fechainicio}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  Fecha Fin
                  <input
                    onChange={this.props.onChange}
                    className="form-control"
                    type="date"
                    name="fechafin"
                    value={this.props.formValues.fechafin}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button onClick={this.handleClick} className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default BusquedaForm;
