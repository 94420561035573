import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./styles/Home.css";
import imagenpie from "../images/logouniflex.png";
import Login from "./Login";

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="container">
          <div className="row">
            <div className="Home__col col-12 col-md-4">
              <h1>
                <p>RECEPCIÓN DE</p>
                <p>DOCUMENTOS</p>
              </h1>
              <img src={imagenpie} alt="Logo" className="img-fluid p-4" />
            </div>

            <div className="Home__col d-md-block col-md-8">
              <Login></Login>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
