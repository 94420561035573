import React from "react";
import { withRouter } from "react-router-dom";

import PageLoading from "../components/PageLoading";
import api from "../api";
import LoginForm from "../components/LoginForm";

class Login extends React.Component {
  state = {
    loading: false,
    error: null,
    form: {
      RUC: "",
      usuario: "",
      password: ""
    }
  };

  componentDidMount() {
    //this.fetchData();
    //this.intervalId = setInterval(this.fetchData, 5000);
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });

    try {
      const data = null;
      console.log(data);
      this.setState({ loading: false, data: data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true, error: null });

    try {
      const rpta = await api.credenciales.login(this.state.form);
      if (rpta.success) {
        this.props.history.push({
          pathname: rpta.data,
          state: this.state.form
        });
      } else {
        throw new Error(rpta.data);
      }
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  render() {
    if (this.state.loading) {
      return <PageLoading />;
    }

    return (
      <React.Fragment>
        <div className="container">
          <div className="row center">
            <div className="col-12 center">
              <h1>Acceso</h1>
              <LoginForm
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                formValues={this.state.form}
                error={this.state.error}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);
