/* const BASE_URL = "http://192.168.1.14:8090"; */
const BASE_URL = "https://www.recepcion.uniflex.com.pe:8443";

const CONTROLLER = "/api/Data";

const URLDESCARGA = BASE_URL + "/api/Data/DescargarArchivo?";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const randomNumber = (min = 0, max = 1) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
const simulateNetworkLatency = (min = 30, max = 1500) =>
  delay(randomNumber(min, max));

async function callApi(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json"
  };

  options.credentials = "include";
  //options.mode = "no-cors";
  const url = BASE_URL + CONTROLLER + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}

async function callApiLogin(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json"
    //Accept: "application/json"
  };

  options.credentials = "include";

  const url = BASE_URL + CONTROLLER + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}

async function callApiNoResponse(endpoint, options = {}) {
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  options.credentials = "include";

  const url = BASE_URL + CONTROLLER + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

const api = {
  descargas: {
    downloadxml(id) {
      return URLDESCARGA + "id=" + id + "&type=xml";
    },
    downloadpdf(id) {
      return URLDESCARGA + "id=" + id + "&type=pdf";
    },
    exportcsv(cp) {
      return callApi("/Exportar", {
        method: "POST",
        body: JSON.stringify(cp)
      });
    }
  },
  credenciales: {
    login(usuario) {
      return callApiLogin("/Login", {
        method: "POST",
        body: JSON.stringify(usuario)
      });
    }
  },
  comprobantes: {
    list() {
      return callApi("/badges");
    },
    search(cp) {
      return callApi(`/Listar`, {
        method: "POST",
        body: JSON.stringify(cp)
      });
    },
    see(cp) {
      return callApi(`/badges`, {
        method: "POST",
        body: JSON.stringify(cp)
      });
    },
    download(cp) {
      return callApi(`/badges`, {
        method: "POST",
        body: JSON.stringify(cp)
      });
    }
  }
};

export default api;
