import React from "react";

import PageLoading from "../components/PageLoading";
import api from "../api";
import BusquedaForm from "../components/BusquedaForm";

class DocumentosRecibidos extends React.Component {
  horaformato = e => {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd + "";
    return today;
  };

  state = {
    loading: false,
    error: null,
    form: {
      factura: false,
      boleta: false,
      notacredito: false,
      notadebito: false,
      ruc: "",
      serie: "",
      numeroinicio: "",
      numerofin: "",
      montoinicio: 0,
      montofin: 0,
      fechainicio: "",
      fechafin: "", //this.horaformato(),
      validosunat: false,
      pendientesunat: false,
      usuarioruc: "",
      entidadFiltro: {
        pageCount: 0,
        offset: 0,
        totalRegistros: 0,
        perPage: 10
      }
    }
  };

  componentDidMount() {
    var objeto = this.props.location.state;
    if (objeto) {
      console.log("OK");
      this.setState({
        form: Object.assign({}, this.state.form, {
          usuarioruc: objeto.usuarioruc
        })
      });
    } else {
      this.props.history.push("/");
    }
  }

  handleChange = e => {
    const value = this.setState({
      form: {
        ...this.state.form,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value
      }
    });
  };

  handleSubmit = async e => {
    //e.preventDefault();
    this.setState({ loading: true, error: null });
    try {
      const xrpta = await api.comprobantes.search(this.state.form);
      var rpta = xrpta[0];
      if (rpta.TotalRegistros > 0) {
        this.props.history.push({
          pathname: "/comprobantes",
          state: {
            data: rpta,
            parametrosconsulta: this.state.form
          }
        });
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: error });
    }
  };

  render() {
    if (this.state.loading) {
      return <PageLoading />;
    }

    return (
      <React.Fragment>
        <div className="Home">
          <div className="container">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-8">
                <h3> Búsqueda de comprobantes recibidos</h3>
              </div>
              <div className="col-2"></div>
            </div>
            <div className="row">
              <div className="col-2"></div>
              <div className="col-8">
                <BusquedaForm
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                  formValues={this.state.form}
                  error={this.state.error}
                />
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DocumentosRecibidos;
