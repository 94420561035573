import React from "react";
import { Link } from "react-router-dom";

import "./styles/MenuPrincipal.css";
import PageLoading from "../components/PageLoading";
import PageError from "../components/PageError";
import api from "../api";

class MenuPrincipal extends React.Component {
  state = {
    loading: true,
    error: null,
    data: undefined
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.state.data = this.props.location.state;
    } else {
      this.props.history.push("/");
    }
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    if (this.state.error) {
      return <PageError error={this.state.error} />;
    }

    if (this.state.loading == true && !this.state.data) {
      return <PageLoading />;
    }

    return (
      <React.Fragment>
        <div className="Home">
          <div className="container">
            <div className="row top-buffer">
              <div className="col-5">
                <h1>Bienvenido: {this.state.data.usuario}</h1>
              </div>
              <div className="col-7" />
            </div>
            <div className="row top-buffer">
              <div className="col-5">
                <Link
                  to={{
                    pathname: "/documentosrecibidos",
                    state: {
                      fromMenuPrincipal: true,
                      usuarioruc: this.state.data.RUC
                    }
                  }}
                  className="btn btn-primary"
                >
                  Documentos recibidos
                </Link>
              </div>
              <div className="col-7" />
            </div>

            {/* <div className="row top-buffer">
              <div className="col-5">
                <Link to="/error" className="btn btn-primary">
                  Documentos emitidos
                </Link>
              </div>
              <div className="col-7" />
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MenuPrincipal;
