import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Layout from "./components/Layout";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Busqueda from "./pages/Busqueda";

import "./App.css";
import MenuPrincipal from "./pages/MenuPrincipal";
import DocumentosRecibidos from "./pages/DocumentosRecibidos";
import DocumentosEmitidos from "./pages/DocumentosEmitidos";
import Comprobantes from "./pages/Comprobantes";

function App() {
  return (
    <BrowserRouter basename="">
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/menuprincipal" component={MenuPrincipal} />
          <Route
            exact
            path="/documentosrecibidos"
            component={DocumentosRecibidos}
          />
          <Route
            exact
            path="/documentosemitidos"
            component={DocumentosEmitidos}
          />
          <Route exact path="/comprobantes" component={Comprobantes} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
