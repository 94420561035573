import React from "react";

import "./styles/BadgeNew.css";
import PageLoading from "../components/PageLoading";
import api from "../api";
import BusquedaForm from "../components/BusquedaForm";

class DocumentosRecibidos extends React.Component {
  state = {
    loading: false,
    error: null,
    form: {
      ruc: "",
      usuario: "",
      password: ""
    }
  };

  componentDidMount() {
    //this.fetchData();
    //this.intervalId = setInterval(this.fetchData,5000);
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });

    try {
      const data = await api.badges.test();
      this.setState({ loading: false, data: data });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true, error: null });

    try {
      await api.badges.create(this.state.form);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };

  render() {
    if (this.state.loading) {
      return <PageLoading />;
    }

    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-5"></div>
            <div className="col-7">
              <BusquedaForm
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                formValues={this.state.form}
                error={this.state.error}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DocumentosRecibidos;
