import React from "react";

class LoginForm extends React.Component {
  render() {
    return (
      <div className="float-sm-none float-md-left">
        <form onSubmit={this.props.onSubmit}>
          <div className="form-group">
            <label>RUC</label>
            <input
              onChange={this.props.onChange}
              className="form-control"
              type="text"
              name="RUC"
              value={this.props.formValues.RUC}
            />
          </div>
          <div className="form-group">
            <label>Usuario</label>
            <input
              onChange={this.props.onChange}
              className="form-control"
              type="text"
              name="usuario"
              value={this.props.formValues.usuario}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              onChange={this.props.onChange}
              className="form-control"
              type="password"
              name="password"
              value={this.props.formValues.password}
            />
          </div>

          <button onClick={this.handleClick} className="btn btn-primary">
            Ingresar
          </button>

          {this.props.error && (
            <p className="text-danger">{this.props.error.message} </p>
          )}
        </form>
      </div>
    );
  }
}

export default LoginForm;
